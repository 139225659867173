<!--
 * @Author: Kiana
 * @Description: Global Login Modal Component
-->

<template>
  <main class="login-modal-wrapper">
    <Modal
        :visible="isShowLoginModal"
        :width="width"
        :custom-class="'login-modal-wrapper-el-dialog'"
        :center="true"
        :append-to-body="false"
        :close-on-click-modal="false"
        @close="closeHandler"
    >
      <loginFast v-show="loginMode === 'fast'" @checkoutLoginMode="checkoutLoginMode"/>
      <loginWechat
          v-show="loginMode === 'wechat'"
          :wechatLoginConfig="wechatLoginConfig"
          :loginStatus="loginStatus"
          @checkoutLoginMode="checkoutLoginMode"
      />
      <ResetPassword v-show="loginMode === 'reset'" @checkoutLoginMode="checkoutLoginMode"/>
    </Modal>
  </main>
</template>

<script>
import Modal from '@/_components/Modal/src/components/Modal';
import loginFast from './LoginFast.vue';
import loginWechat from './LoginWechat.vue';
import ResetPassword from './ResetPassword';
import {GetWeChatQrcode, GetWechatIsScan} from '@/_api/sys/user';

export default {
  name: 'ComLoginModal',
  data() {
    return {
      isShowLoginModal: true,
      loginMode: 'fast',
      wechatLoginConfig: null,
      timer: null,
      loginStatus: {code: 0, msg: ''}
    };
  },
  props: {
    width: {
      type: String,
      default: '380px'
    }
  },
  components: {Modal, loginFast, loginWechat, ResetPassword},
  watch: {},
  computed: {},
  filters: {},
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    checkoutLoginMode(mode) {
      if (mode === 'wechat') {
        this.GetToWechatQrcode();
      }
      this.loginMode = mode;
    },
    async GetToWechatQrcode() {
      try {
        const {data} = await GetWeChatQrcode();
        this.wechatLoginConfig = data;
        this.GetWechatScanConfig();
      } catch (err) {
        this.$notify({
          type: 'error',
          message: err
        });
      }
    },
    closeHandler() {
      this.$store.dispatch('useUserStore/needLoginModal', false);
    },
    GetWechatScanConfig() {
      this.timer = setInterval(() => {
        GetWechatIsScan({random_code: this.wechatLoginConfig.random_code}).then(({data, statusCode, message}) => {
          if (+statusCode === 200) {
            let theData = data?.login_info.user
            let loginData = data?.login_info
            data?.is_scan == 1 && clearInterval(this.timer);
            // 注册用户和快速登录用户都有 手机号嘛不用去绑定
            [1,3].includes(parseInt(theData?.type)) && this.statusBackMsg(10, loginData);
            // 匿名用户需要去绑定手机号
            parseInt(theData?.type) === 2 && this.statusBackMsg(9, loginData);
            // console.log('is_scan:'+data.is_scan)
          } else {
            this.statusBackMsg(1);
            this.$notify({
              type: 'error',
              message: message
            });
          }
        });
      }, 3000);
    },
    statusBackMsg(statusCode, data) {
      let _this = this
      this.loginStatus.code = statusCode
      switch (parseInt(statusCode)){
        case 1:
          this.loginStatus.msg = '点击重试'
          break
        case 2:
          this.loginStatus.msg = '点击刷新'
          break;
        case 9:// 未绑定手机
          this.loginStatus.msg = '扫码成功'
          this.$store.dispatch('useUserStore/setLoginStatusInfo', data);
          setTimeout(function (){
            _this.$router.push('/bind/mobile')
          },2300);
          break
        case 10:
          this.loginStatus.msg = '扫码成功'
          setTimeout(function (){
            _this.$store.dispatch('useUserStore/setLoginStatusInfo', data);
            _this.$store.dispatch('useUserStore/needLoginModal', false)
          },2300);
          break
      }
      // console.log('****************************')
      // console.log('code:'+statusCode)
      // console.log('data:')
      // console.log(data)
      // console.log('loginStatus:')
      // console.log(this.loginStatus)
      // console.log('****************************')
    }
  }
};
</script>

<style lang="less" scoped></style>
