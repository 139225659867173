import Vue from 'vue';
import Modal from './src/components/Modal.vue';

const ModalConstrutor = Vue.extend(Modal);

Modal.install = (config) => {
  const instance = new ModalConstrutor({ propsData: { ...config } }).$mount();
  // document.body.appendChild(instance.$el);
  // Vue.nextTick(() => {
  //   instance.visible = true;
  // });
  return instance;
};

export default Modal;
