<template>
	<el-cascader
		autocomplete
		placeholder="请选择地址,可搜索"
		filterable
		clearable
		:props="{ value: 'c', label: 'n', children: 's' }"
		v-model="region"
		:options="options"
		:style="regionStyle"
		@change="handleChange"
	></el-cascader>
</template>

<script>
export default {
	name: 'Region',
	props: {
		regionStyle: {
			default: ''
		},
		checkedValue: {
			default: 0
		}
	},
	data() {
		return {
			region: '',
			options: []
		};
	},
	watch: {
		checkedValue(newvalue) {
			if (newvalue > 0) {
				this.checkedVal(Number(newvalue));
			}
		}
	},
	created() {
		console.log( this.checkedValue )
		this.options = this.$regionJson.data;
	},
	methods: {
		handleChange() {
			this.$emit('reginCallBack', this.region);
		},
		cleanVal() {
			this.region = '';
		},
		checkedVal(val) {
			// 回调方式 this.$refs.RegionVisble.checkedVal(item.region_id);
			if (val > 0) {
				this.options.forEach(One => {
					One.s.forEach(Two => {
						Two.s.forEach(Three => {
							if (val == Three.c) {
								this.region = [One.c, Two.c, Three.c];
							}
						});
					});
				});
			}
		}
	}
};
</script>
