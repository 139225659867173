import request from './ajax'
import qs from 'qs'
// 用户名和密码
export const requestLogin = (data) => request({
    method: "post",
    url: "/passport/login",
    params: data
})
// 登出
export const requestLogout = (data) => request({
    method: "post",
    url: "/passport/logout",
    params: data
})
//获取验证码
export const requestSendCode = (data) => request({
    method: "post",
    url: "/passport/sendcode",
    params: data
})
// 获取邮箱验证码
export const requestSendEmailCode = (data) => request({
    method: "post",
    url: "/passport/emailcode",
    data: qs.stringify( data )
})

export const getUserBindList = (data) => request({
        method: "post",
        url: "/passport/bind/list",
        data: qs.stringify( data )
    })

export const bindEmail = (data) => request({
    method: "post",
    url: "/passport/bind/email",
    data: qs.stringify( data )
})

// 注册
export const requestReg = (data) => request({
    method: "post",
    url: "/passport/reg",
    params: data
})

// 找回密码 1
export const requestFindPasswordOne = (data) => request({
    method: "post",
    url: "/passport/find1",
    params: data
})

// 找回密码 2
export const requestFindPasswordTwo = (data, token) => request({
    method: "post",
    url: "/passport/find2",
    params: data,
    headers: {
        Authorization: 'Bearer ' + token
    }
})

export const requsetPasswordChange = (data) => request({
    method: 'post',
    url: '/uc/changePassword',
    data: qs.stringify(data),
})

// 微信登录
export const requestWechatQrcode = ()=> request({
    method:"get",
    url:"/wechat/pcqrcode",
})

//查询微信是否扫码
export const requestWechatQuery = (data)=> request({
    method:"get",
    url:"/wechat/pcqrcode/query",
    params:data
})

//绑定第三方账号到一个正式的用户
export const requestWechatBind = (data)=> request({
    method:"post",
    url:"/wechat/pcqrcode/bind",
    params:data
})


