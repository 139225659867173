<!--
 * @Author: Kiana
 * @Description: Global Login Modal Component of Wechat
-->

<template>
  <div class="login-modal-wechat">
    <header class="login-modal-wechat-title">
      <div class="login-modal-wechat-title-icon">
        <isvg icon-class="logo-text" class-name="logo"/>
      </div>
    </header>
    <div class="login-modal-wechat-body">
      <div class="login-modal-wechat-body-img" v-loading="isShowLoading">
        <img :src="qrcode_url" alt="wechat-icon"/>
        <!-- msg -->
        <div class="login-model-wechat-message" :style="{'display': statusInfo.code>0? 'block': 'none'}">
          <p class="message-icon" v-if="statusInfo.code==9||statusInfo.code==10">
            <i class="iconfont icon-success font-24 g"></i>
          </p>
          <p class="message-icon" v-if="statusInfo.code==1">遇到问题</p>
          <p class="message-icon" v-if="statusInfo.code==2">二维码已过期</p>
          <p @click="statusInfo.code==1||statusInfo.code==2?toReset():''" >{{ statusInfo.msg }}</p>
        </div>
      </div>
    </div>
    <footer class="login-modal-wechat-footer">
      <div class="footer-top">
        <span>请使用</span>
        <span>&nbsp;微信&nbsp;</span>
        <span>扫描二维码登录</span>
      </div>
      <div class="footer-bottom">
        <span>已有账号？</span>
        <span @click="changeLoginMode">前往登录</span>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      isShowLoading: true,
      statusInfo: {code: 0, msg: ''}
    };
  },
  props: {
    wechatIconUrl: {
      type: String,
      default: ''
    },
    wechatLoginConfig: {
      type: Object,
      default: () => {
      }
    },
    loginStatus: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {},
  watch: {
    qrcode_url() {
      this.isShowLoading = false;
    },
    loginStatus(newv){
      this.statusInfo = newv
    }
  },
  computed: {
    qrcode_url() {
      return this.wechatLoginConfig?.qrcode_url;
    }
  },
  filters: {},
  created() {
  },
  mounted() {
    this.statusInfo = this.loginStatus
  },
  beforeDestroy() {
  },
  methods: {
    changeLoginMode() {
      this.$emit('checkoutLoginMode', 'fast');
    },
    toReset(){
      this.isShowLoading = true
      this.statusInfo.code = 0
      let _this = this
      setTimeout(function (){
        _this.isShowLoading = false
      },2300);
      this.$emit('GetToWechatQrcode', true)
    }
  }
};
</script>

<style lang="less" scoped>
.login-modal-wechat {
  width: 100%;
  text-align: center;

  &-title {
    display: flex;
    justify-content: center;

    &-icon {
      .logo {
        width: 162px;
        height: 50px;
      }
    }
  }

  &-body {
    margin-top: 0px !important;

    .login-model-wechat-message {
      display: none;
      width: 100%;
      position: absolute;
      height: 50%;
      background-color: rgba(255, 255, 255, 0.9);
      top: 30%;
      right: 0;

      p {
        margin-top: 20px;
        cursor: pointer;
      }
      p:last-of-type {
        line-height: 24px;
      }
      .message-icon {
        margin-top: 40px;
      }
    }

    &-img {
      width: 170px;
      height: 170px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-footer {
    position: relative;
    margin-top: 20px;
    text-align: center;
    box-sizing: content-box;
    font-size: 14px;
    color: #575757;

    .footer-top > span:nth-last-of-type(2) {
      color: #00a2e6;
    }

    .footer-bottom {
      margin-top: 20px;
      font-size: 12px;

      & > span:last-of-type {
        cursor: pointer;
        color: #e4007f;
      }
    }
  }
}
</style>
