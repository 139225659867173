import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import './assets/all.css';
import '@/../public/css/reset.css';
import '@/../public/css/element.css';
import './assets/css/main.css';
import errorImg from '@/assets/banner/Default-Avatar.jpg';
import loadingImg from '@/assets/loading.jpg';
import 'swiper/css/swiper.css';
import * as api from '@/api/index'; //引入api
import { creatPermissionGuard } from '@/permission'; // 路由守卫
import urlencode from 'urlencode'; // urlencode
import VueCookie from 'vue-cookie'; // cookie
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; //坑一
import VueLazyload from 'vue-lazyload'; // 图片懒加载 这个插件提供一个全局指令v-lay
import Carousel from '@/components/Carousel'; //注册全局的轮播图组件
import regionJson from '../public/js/region'; //地区地址json
// utils 方法
import moneyFormat from '@/utils/moneyFormat.js'; // 注册金额格式化过滤器
import redirectUrl from '@/utils/redirectUrl.js'; // 在所有页面都可以获取并带来源参数
import loginSetting from '@/utils/loginSetting.js'; // 统一获取 cookie - token，userInfo
// 公用组件
import Modal from '@/_components/Modal/index'; // 全局 messages 组件
import Region from '@/components/Region/Region.vue';
import { $Http } from '@/utils/http/axios';
Vue.prototype.$api = api;
Vue.prototype.$Http = $Http;

router.beforeEach((to, from, next) => {
  store.dispatch('useUserStore/needLoginModal', false);
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

// 页面跳转后定位到头部
router.afterEach((to, from) => {
  let bodySrcollTop = document.body.scrollTop;
  if (bodySrcollTop !== 0) {
    document.body.scrollTop = 0;
    return;
  }
  let docSrcollTop = document.documentElement.scrollTop;
  if (docSrcollTop !== 0) {
    document.documentElement.scrollTop = 0;
  }
});

// 路由守卫
creatPermissionGuard(router);
//cookie
Vue.prototype.$cookie = VueCookie;
//统一获取 cookie - token，userInfo
Vue.prototype.$loginSetting = loginSetting;
// urlencode
Vue.prototype.$urlencode = urlencode;
// 在所有页面都可以获取并带来源参数
Vue.prototype.$redirectUrl = redirectUrl;
//注册全局的轮播图组件
Vue.component(Carousel.name, Carousel);
// ElementUI
Vue.use(ElementUI);
//图片懒加载 这个插件提供一个全局指令v-lay
Vue.use(VueLazyload, {
  //设置图片懒加载默认图片
  preLoad: 1,
  loading: loadingImg,
  error: errorImg,
  attempt: 1 // 加载错误后的重试次数
});
Vue.config.productionTip = false;
//地区地址json
Vue.prototype.$regionJson = regionJson;
// 注册金额格式化过滤器
Vue.filter('moneyFormat', moneyFormat);
// 全局 messages 组件
Vue.prototype.$creatModal = Modal.install;

// 公用组件
Vue.component('Region', Region);

//---------------------------------------------------------new-start
// import {router} from './router/_index';
import './assets/css/main.css';
import { defaultLayout, loginLayout } from '@/layout';
import { Isvg } from '@/_components/Isvg';
const requireAll = (requireContext) => requireContext.keys().map(requireContext);
const req = require.context('@/assets/icons/svg', false, /\.svg$/);
requireAll(req);
Vue.component('Isvg', Isvg);
Vue.component('default-layout', defaultLayout);
Vue.component('login-layout', loginLayout);
// import {creatPermissionGuard} from "@/permission";// 路由守卫
// creatPermissionGuard(router)
const jump = (url) => {
  router.push(url);
};
Vue.prototype.$Jump = jump;
Vue.prototype.$Static = process.env.VUE_APP_GLOB_CDN;
//---------------------------------------------------------new-end
/************** 表单验证器 **************/
import { ValidationObserver, ValidationProvider, extend, validate } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', { ...required, message: '请输入{_field_}' });
extend('mobile', {
  validate: (value) => {
    let reg = /^1([0-9])\d{9}$/;
    return reg.test(value);
  },
  message: '手机号码格式错误'
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.prototype.$validate = validate;
/************** 表单验证器end **************/

export const vueInstance = new Vue({
  render: (h) => h(App),
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
  data: {
    isLogin: loginSetting.isLogin() ? true : false, // 用于 判断是否已经登录
    current_token: VueCookie.get('gogoworkToken'), // 获取存储的token
    user: JSON.parse(VueCookie.get('userData'))
  }
}).$mount('#app');
