export const SIDE_BAR_MINI_WIDTH = 48;
export const SIDE_BAR_SHOW_TIT_MINI_WIDTH = 80;

export const ContentEnum = {
  // auto width
  FULL: 'full',
  // fixed width
  FIXED: 'fixed'
};

// menu theme enum
export const ThemeEnum = {
  DARK: 'dark',
  LIGHT: 'light'
};

export const SettingButtonPositionEnum = {
  AUTO: 'auto',
  HEADER: 'header',
  FIXED: 'fixed'
};

export const SessionTimeoutProcessingEnum = {
  ROUTE_JUMP: 'ROUTE_JUMP',
  PAGE_COVERAGE: 'PAGE_COVERAGE'
};

/**
 * 权限模式
 */
export const PermissionModeEnum = {
  // role
  ROLE: 'ROLE',
  // black
  BACK: 'BACK',
  // route mapping
  ROUTE_MAPPING: 'ROUTE_MAPPING'
};

//  Route switching animation
export const RouterTransitionEnum = {
  ZOOM_FADE: 'zoom-fade',
  ZOOM_OUT: 'zoom-out',
  FADE_SIDE: 'fade-slide',
  FADE: 'fade',
  FADE_BOTTOM: 'fade-bottom',
  FADE_SCALE: 'fade-scale'
};
