<template>
  <div id="default-layout" class="default-layout">
    <PublicHeader/>
    <div class="default-layout-main">
      <routerView/>
    </div>
    <PublicFooter/>
  </div>
</template>

<script>
import {PublicHeader} from "@/_components/PublicHeader";
import {PublicFooter} from "@/_components/PublicFooter";

export default {
  name: "default.vue",
  components: {PublicHeader, PublicFooter}
}
</script>

<style lang="less" scoped>
.default-layout{
  &-main{
    padding-top: @header_height;
    min-height: calc(100vh - @header_height);
  }
}
</style>