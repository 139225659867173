<template>
  <div class="login-input">
    <ValidationObserver ref="login-form">
      <div class="login-input-content">
        <div class="login-input-content-phone">
          <ValidationProvider
            rules="required|mobile"
            name="手机账号"
            v-slot="{ errors }"
            mode="passive"
          >
            <input
              class="login-input"
              type="number"
              ref="input1"
              placeholder="请输入手机号码"
              v-model.trim.number="account"
              :autofocus="true"
              min="1"
            />
            <p class="login-error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div class="login-input-content-code" v-if="activeIndex === 0 || mode === 'reset'">
          <ValidationProvider rules="required" name="验证码" v-slot="{ errors }" mode="passive">
            <input
              v-model.trim="code"
              name="password"
              type="text"
              placeholder="请输入验证码"
              class="login-input"
            />
            <button
              class="login-send-btn"
              :class="{ 'login-send-btn-disabled': isSendCode }"
              @click="SendCodeHandler"
              ref="buttonNo"
              >{{ codeContent }}
            </button>
            <p class="login-error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div class="login-input-content-password" v-if="activeIndex === 1 || mode === 'reset'">
          <ValidationProvider
            rules="required"
            name="密码"
            v-slot="{ errors }"
            mode="passive"
            style="user-select: none"
          >
            <input
              ref="showPas"
              :type="isShowPassword ? '' : 'password'"
              name="password"
              v-model.trim="password"
              placeholder="请输入密码"
              class="login-input"
            />

            <Isvg
              :icon-class="isShowPassword ? 'eye-open' : 'eye-close'"
              @click.native="changeEyeStatus"
              class-name="icon-login-modal-password"
            />
            <p class="login-error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div class="login-input-content-btn">
          <Button type="button" @click="submitHandler()" :loading="useLoading">{{
            buttonText
          }}</Button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
  import { Button } from 'element-ui';
  import { getCode } from '@/_api/sys/user';
  import { ValidationObserver, extend, validate } from 'vee-validate';
  import qs from 'qs';
  import { required } from 'vee-validate/dist/rules';
  import { resetPassword } from '@/_api/sys/user';

  extend('required', { ...required, message: '请输入{_field_}' });
  extend('mobile', {
    validate: (value) => {
      let reg = /^1([0-9])\d{9}$/;
      return reg.test(value);
    },
    message: '手机号码格式错误'
  });
  export default {
    name: '',
    data() {
      return {
        login_types: {
          ACCOUNT: 1, //'账号密码登录',
          ANYONE: 2, //'匿名登录',
          FAST: 3 //'快捷登录'
        },
        useLoading: false,
        isShowPassword: false,
        account: '',
        code: '',
        password: '',
        isSendCode: false,
        codeContent: '发送验证码',
        //等待时间
        wait: 60,
        //验证码类型，默认发送快速登录类型，详情查看后端文档
        code_type: '2',
        qs
      };
    },
    props: {
      activeIndex: {
        type: [Number, String],
        default: 0
      },
      remember_login: {
        type: Boolean,
        default: false
      },
      mode: {
        type: String,
        default: 'fast'
      }
    },
    components: { ValidationObserver, Button },
    watch: {},
    computed: {
      buttonText() {
        if (this.mode === 'reset') {
          return '重置';
        } else {
          return '登录';
        }
      }
    },
    filters: {},
    created() {
      this.keysController();
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
      submitHandler() {
        this.$refs['login-form'].validate().then((success) => {
          if (!success) {
            return;
          } else {
            const type =
              this.activeIndex === 0 ? this.login_types['FAST'] : this.login_types['ACCOUNT'];

            const data = {
              type,
              account: this.account,
              password: this.password,
              code_type: this.code_type,
              remember_login: this.remember_login ? 1 : 0,
              code: this.code
            };
            if (this.mode === 'reset') {
              this.sendResetPassword();
            }

            this.$store.dispatch('useUserStore/login', data);
          }
        });
      },
      SendCodeHandler() {
        this.isSendCode ||
          validate(this.account, 'required|mobile', {
            name: '手机号'
          }).then((res) => {
            if (!res.valid) {
              this.$notify({
                message: '手机号格式错误',
                type: 'error'
              });
              return;
            }
            this.isSendCode = true;
            this.codeContent = this.wait + '秒';
            let timer = setInterval(() => {
              this.wait--;
              this.codeContent = this.wait + '秒';
              if (this.wait < 0) {
                clearInterval(timer);
                timer = null;
                this.wait = 60;
                this.codeContent = '重新发送验证码';
                this.isSendCode = false;
              }
            }, 1000);
            this.toSendCode();
          });
      },
      async toSendCode() {
        const { account, code_type } = this;
        const result = await getCode({ account, code_type });
        this.$notify({
          message: result.data,
          type: 'success'
        });
      },
      async sendResetPassword() {
        const res = await resetPassword();
      },
      changeEyeStatus() {
        this.isShowPassword = !this.isShowPassword;
      },
      //按钮监听键盘
      keysController () {
        let that = this
        //监听键盘按钮
        document.onkeydown = function (event) {
          var e = event || window.event;
          var keyCode = e.keyCode || e.which;
          switch (keyCode) {
            case 13://enter
              that.submitHandler()
              break;
            default:
              break;
          }
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  @input-height: 36px;

  .login-input {
    &-content {
      .login-input {
        width: 100%;
        height: @input-height;
        font-size: 12px;
        font-weight: 400;
        color: #575757;
        text-indent: 10px;
      }

      p[class='login-error'] {
        color: red;
        text-align: left;
        margin-top: 5px;
      }

      &-phone,
      &-code,
      &-password,
      &-btn {
        width: 100%;
        margin-top: 20px;
      }

      &-code {
        .login-input {
          width: 60%;
          vertical-align: middle;
        }

        .login-send-btn {
          vertical-align: middle;
          width: 40%;
          border: none;
          cursor: pointer;
          color: #fff;
          height: @input-height;
          background-color: #00a2e6;
          font-size: 14px;
        }

        .login-send-btn-disabled {
          background-color: #9ca3af;
          cursor: not-allowed;
        }
      }

      &-password {
        position: relative;

        .icon-login-modal-password {
          width: 16px;
          height: 16px;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
        }
      }

      &-btn {
        ::v-deep .el-button {
          width: 100%;
          color: #fff;
          background-color: #00a2e6;
          height: @input-height;
        }
      }
    }
  }
</style>
