export default (amountFen, decimals = 2, thousands_sep = ',', dec_point = '.') => {
    let amountYuan = amountFen / 100;
    amountYuan = (amountYuan + '').replace(/[^0-9+-Ee.]/g, '');
    var n = !isFinite(+amountYuan) ? 0 : +amountYuan,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point;
    var s = n.toString().split('.');
    var re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
    }
    if( s[1] != undefined ){
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        } else {
            // console.log( 's[1]s[1]s[1]s[1]s[1]')
            // console.log( s[1] )
            s[1] = s[1].substring(0, prec); //小数点位数超出长度时截取前面的位数
        }
    }
    return s.join(dec);
};
