import { PageEnum } from '@/enums/pageEnum';
import { ROLES_KEY, TOKEN_KEY, USER_INFO_KEY } from '@/enums/cacheEnum';
import {getAuthCache, setAuthCache, clearAuthCache} from '@/utils/auth';
import { doLogout, loginApi } from '@/_api/sys/user';
import cookie from 'vue-cookie';

// import { doLogout, getUserInfo, loginApi } from '@/api/sys/user';
import { useMessage } from '@/hooks/web/useMessage';
import router from '@/router';
// import { usePermissionStore } from '@/store/modules/permission';
// import { PAGE_NOT_FOUND_ROUTE } from '@/router/routes/basic';
// import { isArray } from '@/utils/is';
// import { h } from 'vue';

// Need to be used outside the setup
// export function useUserStoreWithOut() {
//   return useUserStore(store);
// }

const { createSuccessModal, createErrorModal } = useMessage();

/**
 * @description commit constant
 */

const mutations = {
  ['SET_TOKEN'](state, payload) {
    state.token = payload;
  },
  ['SET_ROLE_LIST'](state, payload) {
    state.roleList = payload;
  },
  ['SET_USER_INFO'](state, payload) {
    state.userInfo = payload;
  },
  ['SET_SESSION_TIMEOUT'](state, payload) {
    state.sessionTimeout = payload;
  },
  ['RESET_STATE'](state, payload) {
    state.token = payload;
  },
  ['SET_LAST_UPDATE_TIME'](state, payload) {
    state.lastUpdateTime = payload;
  },
  ['SET_LOGIN_MODAL'](state, payload) {
    state.isShowLoginModal = payload;
  }
};

export const useUserStore = {
  namespaced: true,
  state: {
    // user info
    userInfo: null,
    // token
    token: undefined,
    // roleList
    roleList: [],
    // Whether the login expired
    sessionTimeout: false,
    // Last fetch time
    lastUpdateTime: 0,
    //show login modal
    isShowLoginModal: false
  },
  getters: {
    getUserInfo(state) {
      // return this.userInfo || {};
      return state.userInfo || getAuthCache(USER_INFO_KEY) || {};
    },
    getToken(state) {
      // return state.token;
      return state.token || getAuthCache(TOKEN_KEY);
    },
    getRoleList(state) {
      // return this.roleList.length > 0 ? this.roleList : 'getAuthCache(ROLES_KEY)';
      return state.roleList.length > 0 ? this.roleList : getAuthCache(ROLES_KEY);
    },
    getSessionTimeout(state) {
      return !!state.sessionTimeout;
    },
    getLastUpdateTime(state) {
      return state.lastUpdateTime;
    }
  },
  actions: {
    setToken({ commit }, { token, expire_time }) {
      // for null or undefined value
      commit('SET_TOKEN', token ? token : '');
      setAuthCache(TOKEN_KEY, token, expire_time);
      if (!token) {
        clearAuthCache(true);
      }
	  cookie.set('gogoworkToken', token)
    },
    setRoleList({ commit }, roleList) {
      commit('SET_ROLE_LIST', roleList);
      setAuthCache(ROLES_KEY, roleList);
    },
    setUserInfo({ commit }, { user, expire_time }) {
      commit('SET_USER_INFO', user);
      commit('SET_LAST_UPDATE_TIME', new Date().getTime());
      setAuthCache(USER_INFO_KEY, user, expire_time);
    },
    setSessionTimeout({ commit }, flag) {
      commit('SET_SESSION_TIMEOUT', flag);
    },
    resetState({ commit }) {
      commit('SET_USER_INFO', null);
      commit('SET_TOKEN', '');
      commit('SET_ROLE_LIST', []);
      commit('SET_SESSION_TIMEOUT', false);
    },
    needLoginModal({ commit }, info = true) {
      commit('SET_LOGIN_MODAL', info);
    },
    setLoginStatusInfo({ dispatch }, data) {
      //createSuccessModal({ message: '登录成功' }); // save token
      dispatch('setToken', data);
      Reflect.deleteProperty(data, 'token');
      dispatch('setUserInfo', data);
      dispatch('afterLoginAction');
    },
    /**
     * @description: login
     */
    // eslint-disable-next-line no-unused-vars
    async login({ dispatch }, params) {
      const res = await loginApi(params);
      if (res) {
        //createSuccessModal({ message: '登录成功' }); // save token
        dispatch('setToken', res.data);
        Reflect.deleteProperty(res.data, 'token');
        dispatch('setUserInfo', res.data);
        dispatch('afterLoginAction');
      }
    },
    // eslint-disable-next-line no-unused-vars
    async afterLoginAction({ dispatch }, goHome) {
      dispatch('needLoginModal', false);
      const currentUrl = router.currentRoute.path
      if (currentUrl.includes('/task/info')) {
        //console.log('to page loading')
      }
      // location.reload();
      // if (!this.getToken) return null;
      // // get user info
      // const userInfo = await this.getUserInfoAction();

      // const sessionTimeout = this.sessionTimeout;
      // if (sessionTimeout) {
      //   this.setSessionTimeout(false);
      // } else {
      //   // const permissionStore = usePermissionStore();
      //   if (!permissionStore.isDynamicAddedRoute) {
      //     const routes = await permissionStore.buildRoutesAction();
      //     routes.forEach((route) => {
      //       router.addRoute(route);
      //     });
      //     router.addRoute(PAGE_NOT_FOUND_ROUTE);
      //     permissionStore.setDynamicAddedRoute(true);
      //   }
      //   goHome && (await router.replace(userInfo?.homePath || PageEnum.BASE_HOME));
      // }
      // return userInfo;
    },
    /**
     * @description: logout
     */
    async logout({ dispatch, getters }, goLogin = false) {
      if (getters.getToken) {
        try {
          await doLogout();
        } catch {
          console.log('注销Token失败');
        }
      }
      dispatch('setToken', { token: undefined });
      dispatch('setSessionTimeout', false);
      dispatch('setUserInfo', {user:null});
	  // 清理 cookie
	  cookie.delete( 'gogoworkToken' )
      if (router.currentRoute.path.includes('/home')) {
        // 用户中心需要先让离开当前页
        goLogin && (await router.push(PageEnum.BASE_LOGIN));
      }
    }

    // /**
    //  * @description: Confirm before logging out
    //  */
    // confirmLoginOut() {
    //   const { createConfirm } = useMessage();
    //   createConfirm({
    //     iconType: 'warning',
    //     title: () => h('span', '温馨提醒'),
    //     content: () => h('span', '是否确认退出系统'),
    //     onOk: async () => {
    //       await this.logout(true);
    //     }
    //   });
    // }
  },
  mutations
};
