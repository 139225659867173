import { warn } from '@/utils/log';
import pkg from '../../package.json';

/**
 * Get the configuration file variable name
 * @param env
 */
export const getConfigFileName = (env) => {
  return `__PRODUCTION__${env.TRIGGER_GLOB_APP_SHORT_NAME || '__APP'}__CONF__`
    .toUpperCase()
    .replace(/\s/g, '');
};

export function getCommonStoragePrefix() {
  const { VUE_APP_GLOB_APP_SHORT_NAME } = getAppEnvConfig();
  return `${VUE_APP_GLOB_APP_SHORT_NAME}__${getEnv()}`.toUpperCase();
}

// Generate cache key according to version
export function getStorageShortName() {
  return `${getCommonStoragePrefix()}${`__${pkg.version}`}__`.toUpperCase();
}

export function getAppEnvConfig() {
  const ENV_NAME = getConfigFileName(process.env);

  // Get the global configuration (the configuration will be extracted independently when packaging)
  const ENV = isDevMode ? process.env : window[ENV_NAME];

  const {
    VUE_APP_GLOB_APP_TITLE,
    VUE_APP_GLOB_API_URL,
    VUE_APP_GLOB_APP_SHORT_NAME,
    VUE_APP_GLOB_API_URL_PREFIX,
    VUE_APP_GLOB_UPLOAD_URL,
  } = ENV;

  if (!/^[a-zA-Z_]*$/.test(VUE_APP_GLOB_APP_SHORT_NAME)) {
    warn(
      `VUE_APP_GLOB_APP_SHORT_NAME Variables can only be characters/underscores, please modify in the environment variables and re-running.`,
    );
  }

  return {
    VUE_APP_GLOB_APP_TITLE,
    VUE_APP_GLOB_API_URL,
    VUE_APP_GLOB_APP_SHORT_NAME,
    VUE_APP_GLOB_API_URL_PREFIX,
    VUE_APP_GLOB_UPLOAD_URL,
  };
}

/**
 * @description: Development mode
 */
export const devMode = 'development';

/**
 * @description: Production mode
 */
export const prodMode = 'production';

/**
 * @description: Get environment variables
 * @returns:
 * @example:
 */
export function getEnv() {
  return process.env.NODE_ENV;
}

/**
 * @description: Is it a development mode
 * @returns:
 * @example:
 */
export function isDevMode() {
  return process.env.NODE_ENV === devMode;
}

/**
 * @description: Is it a production mode
 * @returns:
 * @example:
 */
export function isProdMode() {
  return process.env.NODE_ENV === prodMode;
}
