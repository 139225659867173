export const headerSettings = {
    list: [
        {
            id: 'index',
            text: '首页',
            path: '/'
        },
        {
            id: 'task_hall',
            text: '任务大厅',
            path: '/task'
        },
        {
            id: 'freelancer',
            text: '专业人才',
            path: '/freelancer'
        },
        {
            id: 'business_of_introduction',
            text: '业务介绍',
            path: '/business'
        },
        {
            id: 'product_of_services',
            text: '产品服务',
            path: '/product'
        },
        // {
        //     id: 'coke',
        //     text: '付费资讯',
        //     path: 'coke'
        // },
        // {
        //     id: '',
        //     text: '',
        //     path: ''
        // },
        // {
        //     id: '',
        //     text: '',
        //     path: ''
        // }
        {
            id: 'release_task',
            text: '发布任务',
            path: '/task/add'
            // path: '/release'
        }
    ],
}
