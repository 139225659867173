<template>
  <div class="public-footer-wrapper">
    <div class="public-footer">
      <div class="public-footer-company">
        <div class="footer-list">
          <a :href="item.url" target="_blank" v-for="(item, index) in footerList" :key="index">{{
            item.text
          }}</a>
        </div>
        <div class="footer-company-info">
          <p>公司全称：北京联众智赢科技有限公司</p>
          <p>八爪灵工 京IPC备：2021019219号-1</p>
        </div>
      </div>
      <hr />
      <div class="public-footer-information">
        <p>联系方式</p>
        <p>联系电话：010-64575166</p>
        <p>联系地址：北京市顺义区绿地启航国际</p>
        <p>咨询邮箱：service@gogowork.cn</p>
      </div>
      <hr />
      <div class="public-footer-qrcode">
        <p>关注我们</p>
        <img src="@/assets/icons/png_jpg/wechat.jpg" alt="wechat" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PublicFooter',
    data() {
      return {
        footerList: [
          {
            text: '服务条款',
            url: 'http://www.gogowork.cn/help/info/6253db653e5476238bc23eb22681f53c'
          },
          {
            text: '隐私协议',
            url: 'http://www.gogowork.cn/help/info/6253d217d47f4539c7b42b85af60cfdc'
          },
          {
            text: '帮助中心',
            url: 'http://www.gogowork.cn/help/info/62544425ade1e93cc835be303f996657'
          },
          {
            text: '关于我们',
            url: 'http://www.gogowork.cn/help/info/62544425ade1e93cc835be303f996657'
          },
          {
            text: '知识园地',
            url: 'http://www.gogowork.cn/help/info/62544425ade1e93cc835be303f996657'
          }
        ]
      };
    }
  };
</script>

<style lang="less" scoped>
  .public-footer-wrapper {
    width: 100vw;
    padding: 70px 0 40px;
    background-color: @color-blue;
    color: #fff;
    font-size: 14px;
    .public-footer {
      max-width: 1200px;
      margin: 0 auto;
      .flex;
      justify-content: space-around;

      & > hr {
        margin: 0 !important;
      }

      &-company {
        .footer-list {
          & > a {
            font-weight: 500;
            color: #fff;

            & + a {
              margin-left: 40px;
            }
          }
        }

        .footer-company-info {
          margin-top: 33px;

          p:last-of-type {
            margin-top: 10px;
          }
        }
      }

      &-information {
        p:first-of-type {
          margin-bottom: 32px;
        }

        p + p {
          margin-top: 10px;
        }
      }

      &-qrcode {
        img {
          width: 60px;
          height: 60px;
          margin: 23px 0;
        }
      }
    }
  }
</style>
