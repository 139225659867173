import Vue from 'vue';
import Vuex from 'vuex';
import cookie from 'vue-cookie';
import { useUserStore } from './modules/user';
import { useModalStore } from './modules/modal';
Vue.use(Vuex);
const state = {
  login_types: {
    account: 1, //'账号密码登录',
    anonymous: 2, //'匿名登录',
    fast: 3 //'快捷登录'
  },
  // token: cookie.get('gogoworkToken'),
  user: cookie.get('userData'),
  identity: 0
};
const mutations = {
  login(state, res) {
    // 后期最好调整为这种存储 登录方式
    cookie.set('gogoworkToken', res.token, res.days);
    cookie.set('userData', res.data, res.days);
    state.token = res.token;
    state.user = res.data;
  }
};
const actions = {};
const getters = {
  inArray: () => (str, _array) => {
    for (var i = 0; i < _array.length; i++) {
      if (str === _array[i]) {
        return true;
      }
    }
    return false;
  }
};
export default new Vuex.Store({
  modules: {
    useUserStore,
    useModalStore
  },
  state,
  mutations,
  actions,
  getters
});
