import router from '@/router';
import urlencode from 'urlencode';
// 跳转至对应页面加 current_url
function jumpTo(url, isHref = false) {
  var current_url = window.location.pathname;
  if (typeof current_url != 'undefined' && current_url != '') {
    if (url.indexOf('?') > 0) {
      url += '&';
    } else {
      url += '?';
    }
    url += 'redirect_url=' + urlencode(current_url);
  }
  if (isHref) {
    window.location.href = url;
  } else {
    router.push(url);
  }
}

// 如果设置 跳转至来源
function jumpToFrom(url, isHref = false) {
  var redirect_url = GetQueryString('redirect_url');
  if (typeof redirect_url != 'undefined' && redirect_url != '' && redirect_url != null) {
    url = redirect_url;
  }
  if (isHref) {
    window.location.href = url;
  } else {
    router.push(url);
  }
}

function GetQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
// 暴露出这些属性和方法
export default {
  jumpTo,
  jumpToFrom,
};
