<template>
  <div class="login-layout">
    <header class="login-layout-header">
      <div class="header-left">
        <div class="header-left-img">
          <img @click="$router.push('/')" src="~@/assets/icons/public_png/logo-text-2x.png" alt=""/>
        </div>
        <div class="header-left-text">自由职业者的家</div>
      </div>
      <div class="header-right" @click="$Jump('/')">返回首页</div>
    </header>
    <main>

      <routerView/>
    </main>
<!--    <footer class="login-layout-footer">-->
<!--      <p>© 2022 gogowork.cn All Rights Reserved 京IPC备：2021019219号-1</p>-->
<!--    </footer>-->
  </div>
</template>

<script>
export default {
  name: "login.vue"
}
</script>

<style lang="less" scoped>
.login-layout {
  width: 100vw;
  position: relative;
  box-sizing: border-box;
  //overflow: hidden;
  &-header {
    position: fixed;
    z-index: 999;
    top: 0;
    //max-width: 1200px;
    //margin: 0 auto;
    height: 64px;
    .flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    width: 100vw;
    padding: 0 70px;

    .header-left {
      .flex;
      align-items: center;

      &-img {
        width: 108px;
        height: 24px;
        cursor: pointer;
      }

      &-text {
        margin-left: 40px;
        position: relative;

        &:after {
          position: absolute;
          content: "";
          top: 0;
          bottom: 0;
          left: -20px;
          width: 1px;
          background-color: #b5b5b5;
        }
      }
    }

    .header-right {
      .pointer;
    }
  }
  &>main{
    margin-top: @header_height;
    //height: calc(100vh - @header_height);
  }

  &-footer {
    position: absolute;
    bottom: 0;
    width: 100vw;
    text-align: center;
    .font-14;
    color: #b5b5b5;
  }
}
</style>
