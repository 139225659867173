// import { isString } from '@/utils/is';
import { vueInstance as _this } from '@/main';
import { Notification as $notify } from 'element-ui';
// function getIcon(iconType) {
//   if (iconType === 'warning') {
//     return <InfoCircleFilled class="modal-icon-warning" />;
//   } else if (iconType === 'success') {
//     return <CheckCircleFilled class="modal-icon-success" />;
//   } else if (iconType === 'info') {
//     return <InfoCircleFilled class="modal-icon-info" />;
//   } else {
//     return <CloseCircleFilled class="modal-icon-error" />;
//   }
// }

/**
 * @description: Create confirmation box
 * @description: 后面根据业务需求再进行调整
 */
function createConfirm(options) {
  //   const iconType = options.iconType || 'warning';
  Reflect.deleteProperty(options, 'iconType');
  const opt = {
    centered: true,
    //  icon: getIcon(iconType),
    ...options
  };
  return this.$creatModal(opt);
}

//如果有业务需求，后面可能会升级为modal形式
const getBaseOptions = () => {
  return {
    title: '通知',
    duration: 3000,
    showClose: false
  };
};

//如果有业务需求，可以定制图标
// eslint-disable-next-line no-unused-vars
function createModalOptions(options, icon) {
  return {
    ...getBaseOptions(),
    ...options
    //  icon: getIcon(icon),
  };
}
function createMessage(options) {
  return $notify(createModalOptions(options));
}
function createSuccessModal(options) {
  return $notify.success(createModalOptions(options));
}

function createErrorModal(options) {
  return $notify.error(createModalOptions(options));
}

function createInfoModal(options) {
  return $notify.info(createModalOptions(options));
}

function createWarningModal(options) {
  return $notify.warning(createModalOptions(options));
}

// Notification.config({
//   placement: 'topRight',
//   duration: 3
// });

/**
 * @description: message
 */
export function useMessage() {
  return {
    // Notification,
    createMessage,
    createConfirm: createConfirm,
    createSuccessModal,
    createErrorModal,
    createInfoModal,
    createWarningModal
  };
}
