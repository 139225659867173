<!--
 * @Author: Kiana
 * @Description: Global Login Modal Component of switching login type
-->
<template>
  <main class="login-switch">
    <div class="login-switch-header">
      <div
        v-for="(item, index) in configList"
        :key="index"
        @click="changeTabHandler(index)"
        class="login-switch-header-tab"
      >
        <button
          type="button"
          class="login-switch-header-tab-btn"
          :class="{ 'tab-active': index === activeIndex }"
          >{{ item.text }}</button
        >
      </div>
    </div>
    <div class="login-switch-body">
      <slot></slot>
    </div>
  </main>
</template>

<script>
  export default {
    name: 'ComLoginSwitch',
    data() {
      return {};
    },
    props: {
      configList: {
        type: Array,
        default: () => []
      },
      activeIndex: {
        type: [String, Number],
        default: 0
      }
    },
    methods: {
      changeTabHandler(index) {
        this.$emit('changeIndexHandler', index);
      }
    }
  };
</script>

<style lang="less" scoped>
  .login-switch {
    &-header {
      display: flex;
      justify-content: center;
      align-items: center;

      &-tab {
        flex: 1;

        &:first-of-type button {
          border-radius: 4px 0 0 4px;
        }

        &:last-of-type button {
          border-radius: 0 4px 4px 0;
        }
      }

      &-tab-btn {
        width: 100%;
        display: inline-block;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        background: #efefef;
        color: #606266;
        -webkit-appearance: none;
        text-align: center;
        box-sizing: border-box;
        outline: 0;
        margin: 0;
        transition: 0.1s;
        font-weight: 500;
        padding: 12px 20px;
        font-size: 14px;
        border: none;
      }

      .tab-active {
        background-color: #00a2e6;
        color: #fff;
      }
    }

    &-body {
      margin-top: 20px;
    }
  }
</style>
