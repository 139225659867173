/*
	该文件是对axios的二次封装，目的：让整个项目发送ajax请求时，通用的配置不用一遍一遍重复编写了
*/
import axios from 'axios'
//引入NProgress做进度条
import NProgress from 'nprogress'
//引入NProgress的样式
import 'nprogress/nprogress.css'

import store from "@/store";
//创建一个axios实例对象，以后整个项目都用这个实例发请求
const ajax = axios.create({
	baseURL: '/api', //请求基本路径
	timeout: 9000, //超时时间
});

//请求拦截器
ajax.interceptors.request.use((config) => {
	//进度条开始走
	NProgress.start()

	var token = store.getters["useUserStore/getToken"]
	if (token) {
		config.headers['Authorization'] = 'Bearer ' + token
	}
	return config
})

//响应拦截器
ajax.interceptors.response.use(
	//能进入到这个回调代表响应成功了
	response => {
		NProgress.done();
		//把服务器返回的真正数据交出去
		return response.data
	},
	//能进入到这个回调代表响应失败了
	error => {
		//返回失败的Promsie实例
		return Promise.reject(error)
	}
)

export default ajax
