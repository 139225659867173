import cookie from 'vue-cookie';
import redirectUrl from '@/utils/redirectUrl';
import request from '@/api/ajax';
import qs from 'qs';
import {setAuthCache} from "@/utils/auth";

export const requestLogin = (data) =>
  request({
    method: 'post',
    url: '/passport/login',
    data: qs.stringify(data),
  });

// 请求退出
export const requesLogout = () =>
  request({
    method: 'post',
    url: '/passport/logout',
  });

// 跳转至对应页面加 current_url
async function toLogin(data, isDialog = false) {
  const res = await requestLogin(data);
  if (res.statusCode == 200) {
    this.setLoginedStatus(res.data, res.data.token, res.remeber_days, isDialog);
  }
}

// 登出
async function toLogout(isDialog = false) {
  const res = await requesLogout();
  if (res.statusCode == 200) {
    cookie.delete('userData');
    cookie.delete('gogoworkToken');
    if (!isDialog) {
      redirectUrl.jumpTo('/login', true);
    } else {
      // 弹窗模式直接刷新页面
      window.location.reload();
    }
  }
}

function setLoginedStatus(data, token, days, isDialog = false) {
  cookie.set('gogoworkToken', token, days);
  cookie.set('userData', JSON.stringify(data), days);
  let currentIdentity = localStorage.getItem('identity_' + data.id);
  if (currentIdentity == null) {
    localStorage.setItem('identity_' + data.id, 1);
  }
  if (!isDialog) {
    setAuthCache( 'TOKEN__', token )
    setAuthCache( 'USER__INFO__', data)

    redirectUrl.jumpToFrom('/home/page', true);
  }else {
    //window.location.reload();
  }
}

// 可用于判断是否登录
function isLogin() {
  return cookie.get('gogoworkToken') != null ? true : false;
}

function getToken() {
  return cookie.get('gogoworkToken');
}

// 用户信息
function getUserInfo() {
  return JSON.parse(cookie.get('userData'));
}

function setUserInfo(data){
  return cookie.set('userData', JSON.stringify(data) );
}

// 暴露出这些属性和方法
export default {
  toLogin, // 请求+登录后状态更改
  toLogout, // 请求+退出后状态更改
  setLoginedStatus, // 设置登陆后的状态
  isLogin, // 判断是否登录
  getUserInfo, // 获取登录信息
  getToken, // 获取token
  setUserInfo
};
