import { $Http } from '@/utils/http/axios';
// import { LoginParams, LoginResultModel, GetUserInfoModel } from './model/userModel';

// import { ErrorMessageMode } from '/#/axios';

const Api = {
  Login: '/api/passport/login',
  Logout: '/api/passport/logout',
  // GetUserInfo: '/getUserInfo',
  // GetPermCode: '/getPermCode',
  // TestRetry: '/testRetry',
  //验证码
  PostCode: '/api/passport/sendcode',
  // 微信登陆
  GetWeChatQrcode: '/api/wechat/pcqrcode',
  // 查询微信是否扫码
  GetWechatIsScan: '/api/wechat/pcqrcode/query',
  //找回密码
  PostNewPassword: '/api/passport/find2'
};

/**
 * @description: user login api
 */
export function loginApi(params) {
  return $Http.post({ url: Api.Login, params });
}

export const resetPassword = (params) => {
  return $Http.post({ url: Api.PostNewPassword, params });
};

export const doLogout = (params) => {
  return $Http.post({ url: Api.Logout, params });
};

export const getCode = (params) => {
  console.log({ params });
  return $Http.post({ url: Api.PostCode, params });
};

export const GetWeChatQrcode = (params) => {
  return $Http.get({ url: Api.GetWeChatQrcode, params });
};

export const GetWechatIsScan = (params) => {
  return $Http.get({ url: Api.GetWechatIsScan, params });
};

// export function testRetry() {
//   return $Http.get(
//     { url: Api.TestRetry },
//     {
//       retryRequest: {
//         isOpenRetry: true,
//         count: 5,
//         waitTime: 1000,
//       },
//     },
//   );
// }
