<!--
 * @Author: Kiana
 * @Description: Global Modal Component

 * 参数	说明	类型	可选值	默认值
 * visible	是否显示 Dialog，支持 .sync 修饰符	boolean	—	false
 * title	Dialog 的标题，也可通过具名 slot （见下表）传入	string	—	—
 * width	Dialog 的宽度	string	—	50%
 * fullscreen	是否为全屏 Dialog	boolean	—	false
 * top	Dialog CSS 中的 margin-top 值	string	—	15vh
 * modal	是否需要遮罩层	boolean	—	true
 * modal-append-to-body	遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素上	boolean	—	true
 * append-to-body	Dialog 自身是否插入至 body 元素上。嵌套的 Dialog 必须指定该属性并赋值为 true	boolean	—	false
 * lock-scroll	是否在 Dialog 出现时将 body 滚动锁定	boolean	—	true
 * custom-class	Dialog 的自定义类名	string	—	—
 * close-on-click-modal	是否可以通过点击 modal 关闭 Dialog	boolean	—	true
 * close-on-press-escape	是否可以通过按下 ESC 关闭 Dialog	boolean	—	true
 * show-close	是否显示关闭按钮	boolean	—	true
 * before-close	关闭前的回调，会暂停 Dialog 的关闭	function(done)，done 用于关闭 Dialog	—	—
 * center	是否对头部和底部采用居中布局	boolean	—	false
 * destroy-on-close	关闭时销毁 Dialog 中的元素	boolean	—	false

 *  —	Dialog 的内容
 * title	Dialog 标题区的内容
 * footer	Dialog 按钮操作区的内容
-->

<template>
  <div>
    <Dialog
      :visible.sync="isShow"
      :title="title"
      :width="width"
      :fullscreen="fullscreen"
      :top="top"
      :modal="modal"
      :center="center"
      :modal-append-to-body="modalAppendToBody"
      :append-to-body="appendToBody"
      :lock-scroll="lockScroll"
      :custom-class="customClass"
      :close-on-click-modal="closeOnClickModal"
      :close-on-press-escape="closeOnPressEscape"
      :show-close="showClose"
      :before-close="beforeClose"
      :destroy-on-close="destroyOnClose"
      @close="closeHandler"
    >
      <!-- <slot name="title"></slot> -->
      <slot>
        <div>{{ content }}</div>
      </slot>
      <!-- <div slot="footer" class="dialog-footer" v-if="buttons.length > 0">
        <Button v-for="(button, index) in buttons" :key="index" @click="button.clickHandler">{{
          button.text
        }}</Button>
      </div> -->
    </Dialog>
  </div>
</template>

<script>
  import { Dialog, Button } from 'element-ui';
  export default {
    name: 'Modal',
    data() {
      return {
        isShow: this.visible
      };
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      title: {
        default: ''
      },
      content: {
        default: ''
      },
      width: {
        default: '50%'
      },
      fullscreen: {
        type: Boolean,
        default: false
      },
      top: {
        default: '15vh'
      },
      modal: {
        type: Boolean,
        default: true
      },
      center: {
        type: Boolean,
        default: false
      },
      modalAppendToBody: {
        type: Boolean,
        default: true
      },
      appendToBody: {
        type: Boolean,
        default: true
      },
      lockScroll: {
        type: Boolean,
        default: true
      },
      customClass: {
        default: ''
      },
      closeOnClickModal: {
        type: Boolean,
        default: true
      },
      closeOnPressEscape: {
        type: Boolean,
        default: true
      },
      showClose: {
        type: Boolean,
        default: true
      },
      beforeClose: {
        type: Object,
        default: () => {}
      },
      destroyOnClose: {
        type: Boolean,
        default: true
      },
      buttons: {
        type: Array,
        default: () => []
      }
    },
    // eslint-disable-next-line vue/no-unused-components
    components: { Dialog, Button },
    watch: {},
    computed: {
      // isShow() {
      //   return this.visible;
      // }
    },
    filters: {},
    created() {},
    mounted() {
      // console.log(Dialog);
      // this.closeModal();
    },
    beforeDestroy() {},
    methods: {
      closeHandler(e) {
        this.$emit('close', e);
      }
      // closeModal() {
      //   this.visible = false;
      // }
    }
  };
</script>

<!-- <style lang="" scoped></style> -->
