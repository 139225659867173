<template>
  <div class="reset-password">
    <div class="reset-password-icon">
      <Isvg icon-class="logo-text" class-name="logo" />
    </div>
    <p
      >如果您之前使用第三方账号登录，因没有绑定手机号，无法直接找回密码，需要通过第三方账号登录！</p
    >
    <LoginInput :mode="'reset'" />
    <div class="reset-password-footer">
      <p @click="changeLoginMode">账密登录</p>
    </div>
  </div>
</template>

<script>
  import LoginInput from '@/_components/LoginModal/src/LoginInput';

  export default {
    name: 'ResetPassword',
    components: {
      LoginInput
    },
    data() {
      return {};
    },
    methods: {
      changeLoginMode() {
        this.$emit('checkoutLoginMode', 'fast');
      }
    }
  };
</script>

<style lang="less" scoped>
  .reset-password {
    &-icon {
      text-align: center;
      margin-bottom: 40px;

      .logo {
        width: 162px;
        height: 38px;
      }
    }

    &-footer {
      text-align: right;
      margin-top: 15px;
      color: @color-blue;
      .pointer;
    }
  }
</style>
