<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
    <LoginModal v-if="isShowLoginModal"/>
  </div>
</template>

<script>
import {$Http} from './utils/http/axios';
import Vue from 'vue';
import {LoginModal} from '@/_components/LoginModal';
import {mapState} from 'vuex';

export default Vue.extend({
  name: 'App',
  components: {LoginModal},
  data() {
    return {
      default_layout: 'default',
      mm: null
    };
  },
  mounted() {
    // setTimeout(() => {
    //   this.$store.dispatch('useUserStore/needLoginModal', true);
    // }, 3000);
    // this.accountInfoApi();
    // this.$store.dispatch('useUserStore/setToken', 3333333);
    // console.log(this.$store.state.useUserStore.token);
    // console.log(this.$store.getters['useUserStore/getToken']);
    // console.log(sessionStorage);
    // console.log(this.$store.state.token);
    // this.$notify({
    //   // type: 'info',
    //   title: '通知',
    //   message: '认证已提交....'
    // });
  },
  computed: {
    ...mapState({
      isShowLoginModal: (state) => state.useUserStore.isShowLoginModal
    }),
    layout() {
      return (this.$route.meta?.layout || this.default_layout) + '-layout'
    },
  },
  methods: {
    // showLogin() {
    //   this.$store.dispatch('useUserStore/needLoginModal', true);
    // },
    async accountInfoApi() {
      const res = await $Http.get({url: '/api/service/category/list', params: {level: 2}});
      console.log(res);
      //   this.mm = data;
    }
  }
});
</script>

<style lang="less" scoped>
@import './assets/fonts/fonts';

* {
  font-family: 'test' !important;
}
</style>
