<template>
  <div class="swiper-container" ref="floor1Swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in scroll" :key="index">
        <img :src="item.imgUrl" />
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>

    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-prev swiper-button-white"></div>
    <div class="swiper-button-next swiper-button-white"></div>
    
  </div>
</template>
<script>
//引入Swiper
import Swiper from "swiper";
export default {
  name: "Carousel",
  props: ["scroll"],
  watch: {
    scroll: {
      //监听属性：立即监听，不管你属性有没有发生变化，立即执行一次。
      immediate: true,
      //监听回调
      handler() {
        //监听到属性变化，立即初始化swiper实例
        this.$nextTick(() => {
          new Swiper(this.$refs.floor1Swiper, {
            loop: true,        
            // 如果需要分页器
            pagination: {
              el: ".swiper-pagination",
              //分页器小球点击的时候切换图片
              clickable: true,
            },
            // 如果需要前进后退按钮
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        });
      },
    },
  },
};
</script>

<style lang="less" scoped>
.swiper-container {
  /* --swiper-navigation-size:0; */
  img {
    width: 860px;
    height: 400px;
  }
}
</style>
