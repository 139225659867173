import { getToken, clearAuthCache } from '@/utils/auth';
import redirectUrl from '@/utils/redirectUrl';

export const creatPermissionGuard = (router) => {
  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requireAuth)) {
      // 判断该路由是否需要登录权限
      if (!getToken()) {
        clearAuthCache();
        // 判断当前的token是否存在 ； 登录存入的token
        if (to.path.includes('/home') || to.path.includes('/task/result/') || to.path.includes('/auth') ) {
          redirectUrl.jumpTo('/login');
        } else {
          next();
        }
      } else {
        if (['/login', '/register'].includes(to.path)) {
          redirectUrl.jumpToFrom('/');
        } else {
          next();
        }
      }
    } else {
      next();
    }
  });
};

// 路由判断登录 根据路由配置文件的参数
