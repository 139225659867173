/**
 * @description commit constant
 */

const mutations = {
  ['SET_LOGIN_MODAL_SHAOW'](state, payload) {
    state.isShowLoginModal = payload;
  }
};

export const useModalStore = {
  namespaced: true,
  state: {
    // public login modal
    isShowLoginModal: false
  },
  actions: {
    setToken({ commit }, info) {
      commit('SET_LOGIN_MODAL_SHAOW', info);
    }
  },
  mutations
};
