<!--
 * @Author: Kiana
 * @Description: Global Login Modal Component of Fast
-->
<template>
  <div class="login-modal-wrapper">
    <header class="login-modal-wrapper-title">
      <div class="login-modal-wrapper-title-icon">
        <isvg icon-class="logo-text" class-name="logo" />
      </div>
    </header>
    <div class="login-modal-wrapper-body">
      <LoginSwitch
        :configList="configList"
        @changeIndexHandler="changeIndexHandler"
        :activeIndex="activeIndex"
      >
        <div>
          <LoginInput :activeIndex="activeIndex" :remember_login="isNeedRemember" />
        </div>
      </LoginSwitch>
      <div class="body-password-options">
        <div class="body-remember">
          <input v-model="isNeedRemember" type="checkbox" name="remember" />
          <span>记住账号密码，10天内免登录</span>
        </div>
        <div class="body-forget" @click="checkoutLoginMode('reset')">
          <span>忘记密码？</span>
        </div>
      </div>
    </div>
    <footer class="login-modal-wrapper-footer">
      <div class="footer-wechat-icon" @click="checkoutLoginMode('wechat')">
        <isvg icon-class="wechat" class-name="wechat" />
      </div>
      <div class="footer-register">
        <span>还没有账号？</span>
        <span @click="jumpRegisterHandler">立即注册</span>
      </div>
    </footer>
  </div>
</template>

<script>
  import LoginSwitch from './LoginSwitch.vue';
  import LoginInput from './LoginInput.vue';

  export default {
    name: '',
    data() {
      return {
        configList: [
          {
            text: '短信验证码登录'
          },
          {
            text: '密码登录'
          }
        ],
        activeIndex: 0,
        isNeedRemember: false
      };
    },
    props: {},
    components: { LoginSwitch, LoginInput },
    watch: {},
    computed: {},
    filters: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
      changeIndexHandler(index) {
        this.activeIndex = index;
      },
      checkoutLoginMode(mode) {
        this.$emit('checkoutLoginMode', mode);
      },
      jumpRegisterHandler() {
        this.$router.push('/register');
      }
    }
  };
</script>

<style lang="less" scoped>
  .login-modal-wrapper {
    width: 100%;
    text-align: center;

    &-title {
      display: flex;
      justify-content: center;

      &-icon {
        .logo {
          width: 180px;
          height: 50px;
        }
      }
    }

    &-body {
      margin-top: 40px;

      .body-password-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        font-size: 12px;

        .body-remember {
          display: flex;
          align-items: center;

          & > input {
            width: 12px;
            height: 12px;
            .pointer;
          }

          & > span {
            margin-left: 10px;
          }
        }

        .body-forget > span {
          color: #00a2e6;
          cursor: pointer;
        }
      }
    }

    &-footer {
      position: relative;
      margin-top: 20px;
      text-align: center;
      box-sizing: content-box;
      padding-top: 15px;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 1px;
        background-color: #b5b5b5;
      }

      .footer-wechat-icon {
        .wechat {
          width: 32px;
          height: 32px;
          .pointer;
        }
      }

      .footer-register {
        margin-top: 20px;

        & > span:last-of-type {
          color: #e4007f;
          cursor: pointer;
        }
      }
    }

    ::v-deep .el-dialog {
      // background: blue;
      &__body {
        padding-top: 10px;
      }
    }
  }
</style>
