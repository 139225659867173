<template>
  <header class="public-header">
    <meta name="description" content="依托互联网通信技术、大数据分析、人工智能算法，为企业用工、人才就业提供一站式解决方案，是落实国家共享经济政策的现代服务平台。自由职业者的家!"/>
    <meta name="keywords" content="八爪灵工,灵活用工,灵工结算,自由职业者的家,自由职业者,众包任务,四流合一"/>
    <section class="header-bar">
      <!-- <div style="width: 290px !important;"> -->
      <div>
        <Isvg @click.native="handleSelect('/')" icon-class="logo-text" class-name="item-logo"/>
        <!-- <span class="titles">北京联众智赢科技有限公司</span> -->
      </div>
      <div v-for="(item,index) in list" :key="index">
        <span :tabindex="index" class="title" :style="{color:currentSelectedRouter==item.path?'#00ace6': '#474747'}" @click="handleSelect(item.path)"> {{ item.text }} </span>
      </div>
      <div v-if="isLogin" class="icon-div">
        <Isvg @click.native="handleSelect('/home/setting/notification')" icon-class="notice" class-name="icon-notice"/>
        <Isvg @click.native="handleSelect('/home/setting/notification?type=system')" icon-class="information" class-name="icon-message"/>
        <el-avatar size="large" class="myAvtar" :src="avatar"/>
        <div class="menu-list">
          <section v-for="(item, index) in nav" :key="index">
            <h4 @click="handleSelect(item.title.path)">{{item.title.name}}</h4>
            <p @click="handleSelect(i.path)" v-for="(i, k) in item.children" :key="k">{{i.name}}</p>
          </section>
          <p class="logoutBtn" @click="handleSelect('logout')">退出登录</p>
        </div>
      </div>
      <div v-else>
        <span @click="handleSelect('/register')">注册</span> / <span @click="handleSelect('login')">登录</span>
      </div>
    </section>
  </header>
</template>

<script>
import {headerSettings} from "@/settings/headerSettings";

export default {
  name: '',
  data() {
    return {
      nav: [
        {
          title: { name: '任务', path: '' },
          children: [
            {name:'我发布的任务', path: '/home/hirer/task/list'},
            {name:'我投标的任务', path: '/home/freelancer/task/list'},
          ]
        },
        {
          title: { name: '账户', path: '' },
          children: [
            {name:'账户总览', path: '/home/account/overview'},
            {name:'充值', path: '/home/account/recharge'},
            {name:'发票', path: '/home/hirer/account/invoice'},
            {name:'我的收藏', path: '/home/collect'},
          ]
        },
        {
          title: { name: '个人主页', path: '/home/page' },
          children: [
            {name:'基本资料', path: '/home/information/base'},
            {name:'认证情况', path: '/home/page'}
          ]
        }
      ],
      currentSelectedRouter: ''
    };
  },
  props: {},
  watch: {
    currentRoutePath(newval){
      this.currentSelectedRouter = newval
    }
  },
  computed: {
    list() {
      return headerSettings?.list
    },
    avatar() {
      const userInfo = this.$store.getters["useUserStore/getUserInfo"]
      return userInfo.avatar;
    },
    isLogin() {
      return this.$store.getters["useUserStore/getToken"]
    },
    currentRoutePath(){
      return this.$route.path
    }
  },
  filters: {},
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    handleSelect(key) {
      if (key === 'logout') {
        this.$store.dispatch('useUserStore/logout', true)
        return;
      }
      if (key === 'login') {
        // this.$router.push('/login')
        this.$store.dispatch('useUserStore/needLoginModal', true)
        return;
      } 
      if(key=='coke'){
        this.$router.push('/')
        document.documentElement.scrollTop = 550;
        document.body.scrollTop = 550;
        return;
      }
      this.$router.push(key)
    }
  }
};
</script>

<style lang="less" scoped>
.titles{
  font-weight: bold;
  display: inline-block;
}
.public-header {
  width: 100vw;
  height: @header_height;
  position: fixed;
  z-index: 999;
  top: 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 8%), 0 2px 4px 0 rgba(0, 0, 0, 12%);
  background: #fff;

  .header-bar {
    width: 1200px;
    height: @header_height;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: center;

    div {
      width: 120px;
      font-size: 16px;
      cursor: pointer;
      .title:focus {
        color: #00ace6;
      }

      &:first-of-type {
        margin-right: 50px;

        .item-logo {
          width: 148px;
          height: 26.25px;
        }
      }

      &:nth-child(2) {
        width: 85px;
      }

      &:last-of-type {
        margin-left: 15vw;
        width: 200px;
        color: @color-blue;
      }

      svg {
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }

      .menu-list {
        position: fixed;
        width: 200px;
        height: auto;
        background-color: #fff;
        z-index: 9999;
        top: @header_height;
        right: 8%;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 20px;
        color: #575757;
        // 淡出淡入
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s .3s, visibility .6s;
        animation: control-pointer .6s;

        section {
          margin-bottom: 20px;
          border-bottom: #f4f4f4 solid 1px;

          &:last-of-type {
            border-bottom: unset;
          }
        }

        section > h4 {
          font-size: 16px;
          font-weight: 400;
        }

        section > p {
          font-size: 12px;
          line-height: 24px;
          margin: 10px 0px;
        }

        .logoutBtn {
          border-top: #f4f4f4 solid 1px;
          font-size: 16px;
          width: 160px;
          height: 40px;
          .flex;
          justify-content: center;
          align-items: center;
          //position: absolute;
          bottom: 0;
        }
      }

      .myAvtar:hover ~ .menu-list {
        // 淡出淡入
        opacity: 1;
        visibility: visible;
        transition: opacity .3s;
        animation: none;
      }

      .menu-list:hover {
        // 淡出淡入
        opacity: 1;
        visibility: visible;
        transition: opacity .3s;
        animation: none;
      }
    }

    .icon-div{
      display: flex;
      align-items: center;
    }
  }

}

</style>
