import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/auth/sign',
      component: () => import('@/views/auth/sign.vue')
    },
    {
      path: '/test/index',
      component: () => import('@/pages/login/index')
    },
    {
      path: '/register',
      meta: { title: '注册', layout: 'login', requireAuth: true },
      component: () => import('@/views/loginRes/register/register.vue')
    },
    {
      path: '/reset',
      meta: { title: '重置密码', layout: 'login' },
      component: () => import('@/views/loginRes/reset/reset.vue')
    },
    {
      path: '/retrievePassword',
      meta: { title: '重置密码', layout: 'login' },
      component: () => import('@/views/loginRes/retrievePassword/retrievePassword.vue')
    },
    {
      path: '/login',
      meta: { title: '登录', layout: 'login', requireAuth: true },
      component: () => import('@/views/loginRes/login/login.vue')
    },
    {
      path: '/identity',
      meta: { title: '身份选择' },
      component: () => import('@/views/loginRes/identity/identity.vue')
    },
    {
      path: '/bind/mobile',
      meta: { title: '绑定手机' },
      component: () => import('@/views/loginRes/bindingPhone/bindingPhone.vue')
    },

    {
      path: '/taskcheck',
      component: () => import('@/views/layout/components/hirer/taskCheck/index copy.vue')
    },
    {
      path: '/business',
      meta: { title: '业务介绍' },
      component: () => import('@/views/home/business')
    },
    {
      path: '/freebusiness',
      meta: { title: '业务介绍' },
      component: () => import('@/views/home/freebusiness')
    },
    {
      path: '/product',
      meta: { title: '产品服务' },
      component: () => import('@/views/home/product')
    },
    {
      path: '/',
      meta: { title: '八爪灵工' },
      // component: () => import('@/views/home/index')
      component: () => import('@/pages/index')
      // component: () => import('@/views/home/indexNew')
    },
    {
      path: '/mall',
      meta: { title: '商城' },
      component: () => import('@/views/mall/home.vue')
    },
    {
      path: '/goods/:goodsId/',
      meta: { title: '商城详情' },
      component: () => import('@/views/detail/detail.vue')
    },
    {
      path: '/check/:goodsId/:productId/:count',
      meta: { title: '确认' },
      component: () => import('@/views/check')
    },
    {
      path: '/purchase',
      meta: { title: '我的爪币审核结果' }
      //component: () => import('@/views/purchase')
    },
    {
      path: '/orderinfo',
      meta: { title: '订单详情' },
      component: () => import('@/views/order/infos.vue')
    },
    {
      path: '/attestation',
      meta: { title: '发票认证' }
      // component: () => import('@/views/attestation')
    },
    {
      path: '/help',
      meta: { title: '帮助中心' },
      component: () => import('@/views/help')
    },
    {
      path: '/help/cate/:id',
      meta: { title: '帮助中心' },
      component: () => import('@/views/help')
    },
    {
      path: '/help/info/:id',
      meta: { title: '帮助详情' },
      component: () => import('@/views/help/helpInfo')
    },
    {
      path: '/service/result/:id',
      meta: { title: '服务审核结果' },
      component: () => import('@/views/service/result')
    },
    {
      path: '/service/update/:id',
      meta: { title: '修改服务' },
      component: () => import('@/views/service/update')
    },
    {
      path: '/service/main/:id',
      meta: { title: '服务详情' },
      component: () => import('@/views/service/info')
    },
    {
      path: '/attestationRes',
      meta: { title: '发票审核结果' }
      //component: () => import('@/views/attestation/attestationRes')
    },
    {
      path: '/service/add',
      meta: { title: '技能服务发布' },
      component: () => import('@/views/service/add')
      // 路由前置守卫
      // beforeEnter: (to, from, next) => {
      //     if (from.path == "/auth") {
      //         next();
      //     } else {
      //         next(false);
      //     }
      //  }
    },
    {
      path: '/service',
      meta: { title: '技能服务' },
      component: () => import('@/views/service/index')
    },
    {
      path: '/notfound',
      meta: { title: '项目未找到' },
      component: () => import('@/components/NotProject')
    },
    {
      path: '/auth', // 兼容不带参数
      meta: { title: '审核认证', requireAuth: true },
      component: () => import('@/views/auth/index')
    },
    {
      path: '/auth/:type/:id', // 需要带参数
      meta: { title: '审核认证' },
      component: () => import('@/views/auth/index')
    },
    {
      path: '/auth/result/:id',
      meta: { title: '审核结果' },
      component: () => import('@/views/auth/result')
    },
    {
      path: '/authselect/:type', // 需要带参数
      meta: { title: '审核认证' },
      component: () => import('@/views/auth/select')
    },
    {
      path: '/authselect/:type/:task_id', // 需要带参数
      meta: { title: '审核认证' },
      component: () => import('@/views/auth/select')
    },
    {
      path: '/task/info/:sn',
      meta: { title: '任务详情' },
      component: () => import('@/views/task/info')
    },
    {
      path: '/task/add',
      meta: { title: '发布任务' },
      component: () => import('@/views/task/create')
    },
    {
      path: '/task/add/:id',
      meta: { title: '发布任务' },
      component: () => import('@/views/task/create')
    },
    {
      path: '/task/update/:id',
      meta: { title: '修改任务' },
      component: () => import('@/views/task/edit')
    },
    {
      path: '/task/result/:id',
      meta: { title: '任务审核', requireAuth: true },
      component: () => import('@/views/task/result')
    },
    {
      path: '/task',
	  name: 'taskIndex',
      meta: { title: '任务大厅' },
      component: () => import('@/views/task/index')
    },
    {
      path: '/freelancer',
      meta: { title: '专业人才' },
      component: () => import('@/pages/freelancer/index')
    },
    {
      path: '/freelancer/info/:id',
      meta: { title: '专业人才详情' },
      component: () => import('@/views/freelancer/info')
    },
    {
      path: '/employer',
      component: () => import('@/components/Employer')
    },
    {
      path: '/SimilarProject',
      component: () => import('@/components/SimilarProject')
    },
    {
      path: '/emailres',
      component: () => import('@/views/email/emailRes')
    },
    {
      path: '/home',
      meta: { requireAuth: true, title: '用户中心' }, // 判断是否需要登录
      /* beforeEnter: ((to, form, next) => {
                if (form.path == "/login" || "/identity") {
                    next()
                } else {
                    let token = this.$cookie.get('gogoworkToken');
                    let user = this.$cookie.get('userData');
                    if ((token === null || token === "") && (user == null || user == "")) {
                        next("/login");
                    } else {
                        next();
                    }

            }), */
      component: () => import('@/views/layout'),
      redirect: { path: 'home/page' },
      mate: {
        requireAuth: true
      },
      children: [
        {
          path: '/tasks',
          component: () => import('@/views/layout/components/hirer/taskInfo/indexCopy')
        },
        {
          path: '/task/evaluation',
          component: () => import('@/views/layout/components/task/evaluation')
        },
        {
          path: 'page',
          meta: { title: '主页' },
          component: () => import('@/views/layout/components/freelancer/homepage')
        },
        {
          path: 'freelancer/task/list',
          meta: { title: '任务列表' },
          component: () => import('@/views/layout/components/freelancer/taskList/index')
        },
        {
          path: 'freelancer/task/work',
          meta: { title: '任务详情' },
          component: () => import('@/views/layout/components/freelancer/taskInfo')
        },
        {
          path: 'freelancer/task/evaluate',
          meta: { title: '评价' },
          component: () => import('@/views/layout/components/freelancer/evaluate')
        },
        {
          path: 'hirer/task/list',
          meta: { title: '项目列表' },
          component: () => import('@/views/layout/components/hirer/tasklist')
        },
        {
          path: 'hirer/task/check',
          meta: { title: '项目验收' },
          component: () => import('@/views/layout/components/hirer/taskCheck')
        },
        {
          path: 'hirer/task/info',
          meta: { title: '项目详情' },
          // component: () => import('@/views/layout/components/hirer/taskInfo')
          component: () => import('@/pages/home/hirer/task/info/index')
        },
        {
          path: 'hirer/task/pass',
          meta: { title: '验收详情' },
          component: () => import('@/pages/home/hirer/task/pass/index')
        },
        {
          path: 'hirer/task/evaluate',
          meta: { title: '评价' },
          component: () => import('@/views/layout/components/hirer/evaluate/index')
        },
        {
          path: 'task/arbitration',
          meta: { title: '仲裁' }
          /* component:()=>
                    import("") */
        },
        {
          path: 'collect',
          meta: { title: '我的收藏' },
          component: () => import('@/views/layout/components/freelancer/collect')
        },
        {
          path: 'information/base',
          meta: { title: '个人资料' },
          component: () => import('@/views/layout/components/freelancer/Information')
        },
        {
          path: 'information/occupation',
          meta: { title: '职业经历' }
          /* component: () =>
                        import ("@/views/layout/components/hirer/taskList") */
        },
        {
          path: 'information/education',
          meta: { title: '教育经历' }
          /* component: () =>
                        import ("@/views/layout/components/hirer/taskList") */
        },
        {
          path: 'information/certificate ',
          meta: { title: '证书荣誉' }
          /* component: () =>
                        import ("@/views/layout/components/hirer/taskList") */
        },
        {
          path: 'information/grade',
          meta: { title: '等级评价' }
          /* component: () =>
                        import ("@/views/layout/components/hirer/taskList") */
        },
        {
          path: 'account/overview',
          meta: { title: '账户总览' },
          component: () => import('@/views/layout/components/freelancer/overview')
        },
        {
          path: 'account/address',
          meta: { title: '收货地址' },
          component: () => import('@/views/layout/components/freelancer/address')
        },
        {
          path: 'account/withdraw',
          meta: { title: '提现' },
          component: () => import('@/views/layout/components/freelancer/withdraw')
        },
        {
          path: 'account/recharge',
          meta: { title: '充值' },
          component: () => import('@/views/layout/components/freelancer/recharge/new_index')
        },
        {
          path: 'account/recharge/detail',
          meta: { title: '充值详情' },
          component: () => import('@/views/layout/components/freelancer/recharge/detail')
        },
        {
          path: 'hirer/account/invoice',
          meta: { title: '发票' },
          component: () => import('@/views/layout/components/hirer/invoice/index')
        },
        {
          path: 'hirer/account/invoice/info',
          meta: { title: '发票详情' },
          hidden: true,
          component: () => import('@/views/layout/components/hirer/invoice/info')
        },
        {
          path: 'hirer/account/invoice/apply',
          meta: { title: '申请发票' },
          hidden: true,
          component: () => import('@/views/layout/components/hirer/invoice/apply')
        },
        {
          path: 'hirer/account/invoice/bill',
          meta: { title: '发票信息' },
          hidden: true,
          component: () => import('@/views/layout/components/hirer/invoice/bill')
        },
        {
          path: 'service',
          meta: { title: '我的服务' },
          component: () => import('@/views/layout/components/freelancer/service')
        },
        {
          path: 'coin/mine',
          meta: { title: '我的爪币' },
          component: () => import('@/views/coin/coin.vue')
        },
        {
          path: 'setting/notification',
          meta: { title: '通知' },
          component: () => import('@/views/layout/components/freelancer/setting/notification')
        },
        {
          path: '/setting/information',
          meta: { title: '消息' },
          component: () => import('@/views/layout/components/freelancer/setting/information')
        },
        {
          path: 'coin/record',
          meta: { title: '兑换记录' },
          component: () => import('@/views/order/order')
        },
        {
          path: 'coin/info',
          meta: { title: '兑换记录' },
          component: () => import('@/views/order/infos')
        },
        {
          path: 'setting/password',
          meta: { title: '修改密码' },
          component: () => import('@/views/layout/components/freelancer/setting/pas')
        },
        {
          path: 'setting/function',
          meta: { title: '功能设置' }
          /* component: () =>
                        import ("@/views/layout/components/hirer/taskList") */
        },
        {
          path: 'setting/third',
          meta: { title: '第三方账号管理' },
          component: () => import('@/views/layout/components/freelancer/setting/third')
        },
        {
          path: 'setting/blog',
          meta: { title: '登录日志' }
          /* component: () =>
                        import ("@/views/layout/components/hirer/taskList") */
        }
      ]
    },
    {
      path: '/mine',
      component: () => import('@/components/MineService')
    },
    {
      path: '/email',
      component: () => import('@/views/email/emailAdress')
    },
    {
      path: '/email/step2',
      component: () => import('@/views/email/emailNext')
    },
    {
      path: '/email/result',
      component: () => import('@/views/email/emailRes')
    },
    {
      path: '/',
      redirect: '/'
    },
    {
      path: '*',
      component: () => import('@/components/NotProject')
    }
  ]
});

export default router;

// 防止重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
